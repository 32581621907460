import { IntegracaoComponent } from './features/integracao/integracao.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './service/auth-guard/auth-guard.service';
import { NotFoundComponent } from 'src/app/features/not-found/not-found.component';
import { HealthcheckComponent } from 'src/app/features/healthcheck/healthcheck.component';


const routes: Routes = [
  // { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'integracao',component: IntegracaoComponent },
  { path: 'login',  loadChildren: () => import('src/app/features/login/login.module').then(m => m.LoginModule) },
  { path: 'index', loadChildren: () => import('src/app/features/index/index.module').then(m => m.IndexModule) },
  { path: 'home', loadChildren: () => import('src/app/features/home/home.module').then(m => m.HomeModule) },
  { path: 'perfil', loadChildren: () => import('src/app/features/perfil/perfil-user.module').then(m => m.PerfilUserModule)},
  { path: 'cadastro',  loadChildren: () => import('src/app/features/auto-cadastro/auto-cadastro.module').then(m => m.CadastroModule) },
  { path: 'procuracao',  loadChildren: () => import('src/app/features/procuracao/procuracao.module').then(m => m.ProcuracaoModule) },
  { path: 'registro',  loadChildren: () => import('src/app/features/registro-acoes/registro-acoes.module').then(m => m.RegistroAcoesModule) },
  { path: 'caixa-postal',  loadChildren: () => import('src/app/features/caixa-postal-eletronica/caixa-postal-eletronica.module').then(m => m.CaixaPostalEletronicaModule) },
  { path: 'healthcheck', component: HealthcheckComponent },
  { path: '404', component: NotFoundComponent, data: { breadcrumb: 'Página não Encontrada' }},
  { path: '**', redirectTo: '404' },
 ];
 
 @NgModule({
 	imports: [
     RouterModule.forRoot(routes, {
       useHash: true,
       anchorScrolling: 'enabled'
     })
 	],
 	exports: [
 		RouterModule
 	]
 })
 export class AppRoutingModule { 

}
