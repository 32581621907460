import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { Router } from '@angular/router';
import { CepService } from 'src/app/service/cep/cep.service';

import swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';

@Component({
  selector: 'app-cep-residencial',
  templateUrl: 'cep-residencial.component.html',
  styleUrls: ['./cep-residencial.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
    ]),
  ],
})
export class cepResidencialComponent implements OnInit {
  signUpForm: FormGroup;
  cepValido = false;
  cepTexto = true;
  tipoContribuinte = 'residencial';
  cepVerificado = false;
  loading = false;
  ibgeCliente: any;
  cliente: any;

  constructor(
    private router: Router,
    private cepService: CepService,
    private formBuilder: FormBuilder,
    private cadastroService: CadastroService
  ) { }

  ngOnInit() {
    this.obterIbgeCliente();
    this.signUpForm = this.formBuilder.group({
      cepResidencial: [
        "",
        Validators.compose([
          Validators.required,
          // Validators.minLength(8),
          // Validators.maxLength(),
        ]),
      ],
    });
  }

  obterIbgeCliente() {
    this.cadastroService.clientes().subscribe(
      res => {
        this.cliente = res.resultado['0']
        this.ibgeCliente = res.resultado['0'].numIbge

        localStorage.setItem('prefeituras', JSON.stringify(this.cliente));
      },
    );
  }

  validarCEP(cep: string) {
    this.loading = true;

    this.cepService.getCep(cep).subscribe(res => {

      if (res.resultado && res.resultado.ibge == this.ibgeCliente) {
        this.cepValido = true;
        this.cepTexto = true;
      } else if (res.resultado.ibge != this.ibgeCliente) {
        swal.fire({
          title: 'Atenção',
          html: 'O Domicílio Eletrônico está disponível apenas para contribuintes do município.',
          icon: 'info',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sair'
        }).then((result) => {
          localStorage.removeItem('perfil');
          localStorage.removeItem('dadosGov');
          localStorage.removeItem('listaCnpjVinculado');
          localStorage.removeItem('procurador');
          localStorage.removeItem('credencialIntegracao');
          localStorage.removeItem('contribuinteEmUso');
          localStorage.removeItem('firstVisit');
          localStorage.removeItem('token');
          localStorage.removeItem('tokenCbo');
          localStorage.removeItem('data');
          localStorage.removeItem('tipo');
          localStorage.removeItem('codCliente');
          localStorage.removeItem('session');

          window.location.href = "https://sso.acesso.gov.br/logout";

          if (window.location.href == "https://sso.acesso.gov.br/logout") {
            this.router.navigate(["/index"]);
            window.location.href = "https://dtesantoandre.giss.com.br";
          }

          this.router.navigateByUrl('/index').then(() => {
            window.location.reload();
          });
        });
      }
      this.loading = false;
    }, error => {
      this.cepTexto = false;
      this.cepValido = false;
      this.loading = false;
    });
  }


  CEPInvalid() {
    if (this.signUpForm.controls["cepResidencial"].value.trim().length == 0) {
      this.signUpForm.controls["cepResidencial"].setValue("");
      console.log('invalido')
    }
  }

  prosseguir() {
    const contribuintes = JSON.parse(localStorage.getItem('codCliente'));

    if (contribuintes) {
      const contribuinteAtivo = contribuintes.find(c => c.ativo === true && c.perfilAcesso.codigo === 2);
      if (contribuinteAtivo) {
        this.router.navigate(['/termo-adesao']);
      } else {
        localStorage.setItem('perfil', 'contribuinte');
        localStorage.setItem('tipo', 'fisica');
        this.router.navigate(['cadastro/contribuinte/dados']);
      }
    } else {
      localStorage.setItem('perfil', 'contribuinte');
      localStorage.setItem('tipo', 'fisica');
      this.router.navigate(['cadastro/contribuinte/dados']);
    }
  }


  naoProsseguir() {
    localStorage.removeItem('perfil');
    localStorage.removeItem('dadosGov');
    localStorage.removeItem('procurador');
    localStorage.removeItem('objUsuario');
    localStorage.removeItem('credencialIntegracao');
    localStorage.removeItem('contribuinteEmUso');
    localStorage.removeItem('firstVisit');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenCbo');
    localStorage.removeItem('data');
    localStorage.removeItem('tipo');
    localStorage.removeItem('codCliente');
    localStorage.removeItem('session');

    window.location.href = "https://sso.acesso.gov.br/logout";

    if (window.location.href == "https://sso.acesso.gov.br/logout") {
      this.router.navigate(["/index"]);
      window.location.href = "https://dtesantoandre.giss.com.br";
    }

    this.router.navigateByUrl('/index').then(() => {
      window.location.reload();
    });
  }
}
