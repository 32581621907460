import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserService } from '../../../service/user-service/user.service';
import { SessionService } from '../../../service/session/session.service';
import { PerfilDataService } from '../../../service/perfil/perfil.service';
import { ConsultaUsuarioService } from '../../../service/consulta-usuario/consultar.user.service';

@Component({
  selector: '[app-user-sidebar]',
  templateUrl: 'user-sidebar.component.html',
  styleUrls: ['./user-sidebar.component.scss'],
})
export class UserSidebarComponent implements OnInit {
  @Output() onSignOut = new EventEmitter<boolean>();
  @Input() userData: string;

  subscription: Subscription;
  userInfo = {};
  selectedCompany = 0;
  fileToUpload: string = null;
  hasImg: boolean = false;
  imageToUpload: any;
  userImg: SafeUrl;
  showImg = false;
  mostrarSuporte: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private session: SessionService,
    private consultaUsuario: ConsultaUsuarioService,
    private domSanitizer: DomSanitizer,
    private perfilService: PerfilDataService,
  ) { }

  ngOnInit() {
    this.verifySession(this.userData);
    this.subscription = this.userService.getInfo().subscribe(data => {
      this.verifySession(data);
    });
  }

  verifySession(data) {
    this.userInfo = data;
    console.log('user data :: ', this.userInfo);
    if (data.usuario && data.usuario.imagem) {
      this.hasImg = true;
      this.userImg = this.domSanitizer.bypassSecurityTrustUrl(
        data['usuario'].imagem.toString(),
      );
    } else {
      data.loggedIn ? this.ongetUserImage() : console.log(data);
      this.hasImg = false;
    }
  }

  setImage() {
    if (this.userInfo['usuario'] && this.userInfo['usuario'].imagem) {
      this.userImg = this.userInfo['usuario'].imagem;
      this.userImg = this.domSanitizer.bypassSecurityTrustUrl(
        'data:image/jpeg;base64,' + this.userImg,
      );
    }
  }


  ongetUserImage() {
    return new Promise((resolve, reject) => {
      this.perfilService.getUserImage().subscribe(
        image => {
          if (image !== null) {
            this.getProcessedImageBlob(image).then(img => {
              resolve(img);
              console.log(img);
              this.hasImg = true;
              this.userImg = this.domSanitizer.bypassSecurityTrustUrl(
                img.toString(),
              );
            });
          } else {
            this.hasImg = false;
            resolve(null);
          }
        },
        error => {
          resolve(null);
        },
      );
    });
  }

  getProcessedImageBlob(imageBlob) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result.toString());
        },
        false,
      );
      reader.addEventListener(
        'error',
        () => {
          console.log('error');
        },
        false,
      );

      if (imageBlob) {
        reader.readAsDataURL(imageBlob);
      }
    });
  }

  fileChangeEvent(event) {
    this.convertToJpg(event.target.files[0]).then((res) => {
      this.imageToUpload = res;
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        reader.readAsDataURL(this.imageToUpload);
        reader.onload = evt => {
          this._handleReaderLoaded(evt);
        };
      }
    })
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.perfilService.alterarFoto(this.imageToUpload).subscribe(res => {
      this.showImg = true;
      this.userService.updateDataWithPhoto(binaryString);
    });
  }

  convertToJpg(image) {
    return new Promise((resolve, reject) => {
      const file = image;
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext('2d');
          ctx.fillStyle = '#ffffff';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], `foto_perfil`, { type: 'image/jpeg' });

            resolve(convertedFile);
          }, 'image/jpeg', 1);
        };
      };
    })
  }

  signOut() {
    this.userService.sendInfo({ loggedIn: false });
    this.session.clearSession();
    this.onSignOut.emit(false);

    localStorage.removeItem('perfil');
    localStorage.removeItem('dadosGov');
    localStorage.removeItem('listaCnpjVinculado');
    localStorage.removeItem('procurador');
    localStorage.removeItem('objUsuario');
    localStorage.removeItem('credencialIntegracao');
    localStorage.removeItem('contribuinteEmUso');
    localStorage.removeItem('firstVisit');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenCbo');
    localStorage.removeItem('data');
    localStorage.removeItem('tipo');
    localStorage.removeItem('codCliente');
    localStorage.removeItem('session');

    window.location.href = "https://sso.acesso.gov.br/logout";

    if (window.location.href == "https://sso.acesso.gov.br/logout") {
      this.router.navigate(["/index"]);
      window.location.href = "https://dtesantoandre.giss.com.br";
    }

    this.router.navigateByUrl('/index').then(() => {
      window.location.reload();
    });
  }

  clickUsuario() {
    console.log('click usuario');
    this.router.navigate(['/perfil/editar']);

    // routerLink=""

    this.onSignOut.emit(false);
  }

}
