import { Usuario } from './../../../model/usuario';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PerfilDataService } from '../../../service/perfil/perfil.service';
import { LoginService } from '../../../service/login/login.service';
import { UserService } from '../../../service/user-service/user.service';
import { SessionService } from '../../../service/session/session.service';
import { trigger, transition, animate, style } from '@angular/animations';
import swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';
import { DataTable } from 'ng2-data-table';
import { ConsultaUsuarioService } from 'src/app/service/consulta-usuario/consultar.user.service';
import { HttpHeaders } from '@angular/common/http';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { of } from 'rxjs';
import { delay, concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-perfil',
  templateUrl: 'perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
  providers: [DataTable]
})
export class PerfilComponent implements OnInit {
  perfis = [];
  dataPerfis: object = {};
  perfil: string = '';
  searchForm: FormGroup;
  searchText = '';
  allow: boolean = false;
  userInfo: Object = {};
  itemsPerView: number = 10;
  selectedEntities: any[];
  procuracoes = [];
  procuracoesTable = [];
  procuracao: Object = {};

  currentPerfil: any = {};

  usuarios: Array<any> = [];
  noUsersOnSearch = false;

  showPage: boolean = false;
  codTermoAdesao: any;

  listaContribuintes: any = [];
  listaPaginada: any;
  dadosIntegracao: any;
  objUsuario: any;
  tipoPessoa: number;
  pagina: number = 1;
  quantidadePaginas: number;
  quantidadePorPagina: number = 10;
  retornarDesativado: boolean = false;

  loading: boolean = false;

  termoProcuracaoOriginal: any;
  codigoCliente: any;
  exigeTermoProcuracao: boolean = false;
  codUsuario: any;
  usuarioSemCadastro: boolean = false;
  listaCNPJ: boolean = false;
  usuarioGovBR: any;
  adesaoAtiva: boolean = false;

  haListaProcuracao: boolean = false
  dadosCnpj: any;
  codigoCnpjSelecionado: any;
  codUsuarioClienteCnpj: any;
  
  tokenAutenticarGov: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private sessionService: SessionService,
    private data: PerfilDataService,
    private consultaUsuario: ConsultaUsuarioService,
    private cadastroService: CadastroService,
  ) {
    localStorage.getItem('token');
  }

  ngOnInit() {
    this.obterTermoProcuracao();
    let usuario = JSON.parse(localStorage.getItem("session"));

    if (JSON.parse(localStorage.getItem("listaCnpjVinculado"))) {
      this.listaCNPJ = true;
      this.usuarioGovBR = JSON.parse(localStorage.getItem("listaCnpjVinculado"));

      if (this.usuarioGovBR.cadastradoDte == false) {
        this.usuarioSemCadastro = true;
        // console.log("dadosListaCnpj:", this.usuarioGovBR);
        return;
      }
      
      this.consultaUsuario.consultarUsuario('0').subscribe(
        res => {
          if (res.error) {
            return;
          }
        })
    }
    if (JSON.parse(localStorage.getItem("dadosGov")) && localStorage.getItem("listaCnpjVinculado") === null) {
      this.usuarioGovBR = JSON.parse(localStorage.getItem("dadosGov"));
      if (
        this.usuarioGovBR.cadastradoDte == false &&
        this.usuarioGovBR.usuario == null
      ) {
        this.usuarioSemCadastro = true;
      }
      // console.log("dadosgov:", this.usuarioGovBR);
    } else if ((usuario && usuario.usuario)) {
      this.codUsuario = usuario.usuario.codigo;
      // console.log("dadosUsuario:", this.usuarioGovBR);
    }

    this.obterTermoAdesao();
    this.validarPerfil();

    localStorage.getItem('token');
  }

  obterSessao() {
    localStorage.setItem('firstVisit', 'true');
    this.loginService.clientes().subscribe(
      res => {
        console.log('obterSessao >> ', res.resultado);
        let response = res.resultado;
        for (let i = 0; i < response.length; i++) {
          if (response[i].perfilAcesso && response[i].perfilAcesso.codigo == 2 && response[i].usuario) {
            let contribuinte = {
              cpfCnpj: response[i].usuario.cpfCnpj,
              dscRazaoSocial: response[i].usuario.nomeRazaoSocial,
              dadoContribuinte: response[i].usuario,
            };
            this.listaContribuintes.push(contribuinte);
          }
        }
        this.organizarPaginacao();
      },
      error => {
        let contribuinte = {
          dscRazaoSocial: this.usuarioGovBR.usuarioGov.nomeCompleto,
          cpfCnpj: null,
          dadoContribuinte: 'valor padrão',
        };

        if (this.usuarioGovBR.usuarioGov) {
          if (this.usuarioGovBR.usuarioGov.cpf) {
            contribuinte.cpfCnpj = this.usuarioGovBR.usuarioGov.cpf;
          } else {
            contribuinte.cpfCnpj = this.usuarioGovBR.usuarioGov.cnpj;
          }
        }

        this.listaContribuintes.push(contribuinte);
        this.organizarPaginacao();
        // this.usuarioSemCadas;tro = true;
      }
    );
  }

  obterTermoAdesao() {
    this.cadastroService.clientes().subscribe(
      res => {
        let cliente = res.resultado['0']
        this.codTermoAdesao = res.resultado['0'].configuracao.termoAdesao.codigo;
        console.log('termo', this.codTermoAdesao);
        localStorage.setItem('prefeituras', JSON.stringify(cliente));
      },
    );
  }

  validarUsuario() {
    localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      if (this.codUsuario == undefined) {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Seu perfil não possui procurações outorgadas.',
            'ok',
            null
          )
        ).then(res => {
          this.router.navigate(['/index']);
        });
        return;
      }
      
        this.consultaUsuario.validarAdesaoAtiva(this.codUsuario, this.codTermoAdesao).subscribe(
          res => {
            if (res['resultado'] == false) {
              this.adesaoAtiva = false;
              resolve(false);
            } else {
              resolve(true);
              this.adesaoAtiva = true;
              localStorage.setItem('firstVisit', 'true');
            }
          },
          error => {
            console.error('Erro ao validar adesão', error);
            reject({ message: 'Erro ao validar adesão' });
          }
        );
      
    });
  }

  contribuinteSelecionado(objContribuinte) {
    this.objUsuario = objContribuinte;
    // console.log('this.objUsuario', this.objUsuario)
    
    this.codigoCliente = objContribuinte.codigo

    if (localStorage.getItem("listaCnpjVinculado") != null) {
        this.consultaUsuario.consultarUsuario(this.objUsuario.cnpj).pipe(
        concatMap(res => {
          if (res.error) {
            return of(this.objUsuario.cnpj).pipe(
              delay(2000),
              concatMap(cnpj => this.consultaUsuario.consultarUsuario(cnpj))
            );
          } else {
            let usuarioGov = {
              cpf: null,
              nomeCompleto: null,
              email: null,
              celular: null,
              cnpjVinculado: this.objUsuario.cnpj,
              dscCnpjVinculado: null
            };

            this.consultaUsuario.autenticarGovBR(usuarioGov).subscribe(
              res => {
                this.tokenAutenticarGov = res.resultado.token;
                localStorage.setItem('token', res.resultado.token);

                this.loginService
                  .obterSessao(this.codUsuarioClienteCnpj)
                  .then(data => {
                    this.userService.sendInfo({
                      loggedIn: true,
                      firstVisit: true,
                      ...data['resultado']
                    });
                    
                    localStorage.setItem('token', this.tokenAutenticarGov);

                    this.router.navigate(['/home']);
                  })
                  .catch(error => {
                    this.handleError(error);
                  });
              },
              error => {
                console.log('error', error)
              },
            );

            this.codUsuarioClienteCnpj = res.resultado.usuarioCliente.codigo;
            return of(res);
          }
        })
      ).subscribe(
        res => {
          localStorage.setItem('codUsuarioCnpj', JSON.stringify(res.resultado));
          this.codigoCnpjSelecionado = localStorage.setItem('codUsuarioCnpj', JSON.stringify(res.resultado));
          this.codUsuario = res.resultado.codigo;
          this.usuarioSemCadastro = false;
          return new Promise((resolve, reject) => {
            this.consultaUsuario.validarAdesaoAtiva(this.codUsuario, this.codTermoAdesao).subscribe(
            res => {
              if (res['resultado'] == false) {
                this.adesaoAtiva = false;
                swal.fire({
                  title: 'Alerta',
                  html: 'Prezado(a), para prosseguir com acesso ao Domicílio Eletrônico do Contribuinte, é necessário aceitar o Termo de Adesão da plataforma.',
                  icon: 'warning',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Prosseguir com Adesão',
                  showCancelButton: true,
                  cancelButtonText: 'Não Prosseguir',
                  reverseButtons: true
                }).then((result) => {
                  if (result.value) {
                    this.router.navigate(['/termo-adesao']);
                    localStorage.setItem('objUsuario', JSON.stringify(this.objUsuario));
                  }
                  else if (result.dismiss === swal.DismissReason.cancel) {
                    localStorage.removeItem('perfil');
                    localStorage.removeItem('dadosGov');
                    localStorage.removeItem('procurador');
                    localStorage.removeItem('listaCnpjVinculado');
                    localStorage.removeItem('credencialIntegracao');
                    localStorage.removeItem('contribuinteEmUso');
                    localStorage.removeItem('firstVisit');
                    localStorage.removeItem('token');
                    localStorage.removeItem('tokenCbo');
                    localStorage.removeItem('data');
                    localStorage.removeItem('tipo');
                    localStorage.removeItem('codCliente');
                    localStorage.removeItem('session');

                    window.location.href = "https://sso.acesso.gov.br/logout";

                    if (window.location.href == "https://sso.acesso.gov.br/logout") {
                      this.router.navigate(["/index"]);
                      window.location.href = "https://dtesantoandre.giss.com.br";
                    }

                    this.router.navigateByUrl('/index').then(() => {
                      window.location.reload();
                    });
                  }
                });
                resolve(false);
              }
              else {
                // if (localStorage.getItem("listaCnpjVinculado") != null) {
                //   this.dadosCnpj = JSON.parse(localStorage.getItem("listaCnpjVinculado"));
                //   // localStorage.setItem('token', this.tokenAutenticarGov);
                //   // console.log('token', localStorage.getItem('token'))
                //   // localStorage.getItem('token')
                //   localStorage.setItem('firstVisit', 'true');
                //   // this.loginService.clientes().subscribe(
                //   //   res => {
                //   //     console.log('loginconfigs >> ', res.resultado);
                //   //     let response = res.resultado;

                //   //     let contribuinte = response.find(item => item.perfilAcesso.codigo == 2);
                //   //     console.log('contribuinte', response);
                //   //     console.log('codUsuarioClienteCnpj', this.codUsuarioClienteCnpj);
                //   //     if (contribuinte) {
                //         this.loginService
                //           .obterSessao(this.codUsuarioClienteCnpj)
                //           .then(data => {
                //             this.userService.sendInfo({
                //               loggedIn: true,
                //               firstVisit: true,
                //               ...data['resultado']
                //             });
                //             this.router.navigate(['/home']);
                //           })
                //           .catch(error => {
                //             this.handleError(error);
                //           });
                //     //   }
                //     // },
                //     error => this.handleError(error)
                //   // );
                // }
                // this.dadosCnpj = JSON.parse(localStorage.getItem("listaCnpjVinculado"));
                // localStorage.getItem('token')
                // console.log('token', localStorage.getItem('token'))
                // localStorage.setItem('firstVisit', 'true');
                  // this.loginService.clientes().subscribe(
                    // res => {
                      // console.log('loginconfigs >> ', res.resultado);
                      // let response = res.resultado;

                      // let contribuinte = response.find(item => item.perfilAcesso.codigo == 2);
                      // console.log('constribuinte', contribuinte);
                      // if (contribuinte) {
                        // this.loginService
                        //   .obterSessao(this.codUsuarioClienteCnpj)
                        //   .then(data => {
                        //     this.userService.sendInfo({
                        //       loggedIn: true,
                        //       firstVisit: true,
                        //       ...data['resultado']
                        //     });
                        //     this.router.navigate(['/home']);
                        //   })
                          // .catch(error => {
                          //   this.handleError(error);
                          // });
                      }
                    error => this.handleError(error)
            },
            error => {
              console.error('Erro ao validar adesão', error);
              reject({ message: 'Erro ao validar adesão' });
            }
          );
        });
      },
      error => {
        swal.fire({
          title: 'Alerta',
          html: 'Este é o seu primeiro acesso. Faz-se necessário validar alguns dados e aderir ao Domicílio Eletrônico para seguir com seu acesso.',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Prosseguir com Adesão',
          showCancelButton: true,
          cancelButtonText: 'Não Prosseguir',
          reverseButtons: true
        }).then((result) => {
          if (result.value && (objContribuinte.cpfCnpj || objContribuinte.cnpj).length === 11) {
            this.router.navigate(['/cep-residencial']);
            localStorage.setItem('objUsuario', JSON.stringify(this.objUsuario));
          }
          if (result.value && (objContribuinte.cpfCnpj || objContribuinte.cnpj).length === 14) {
            localStorage.setItem('pessoaJuridica', JSON.stringify(this.objUsuario));
            this.router.navigate(['/cep-comercial']);
          }
          else if (result.dismiss === swal.DismissReason.cancel) {
            localStorage.removeItem('perfil');
            localStorage.removeItem('dadosGov');
            localStorage.removeItem('procurador');
            localStorage.removeItem('listaCnpjVinculado');
            localStorage.removeItem('credencialIntegracao');
            localStorage.removeItem('contribuinteEmUso');
            localStorage.removeItem('firstVisit');
            localStorage.removeItem('token');
            localStorage.removeItem('tokenCbo');
            localStorage.removeItem('data');
            localStorage.removeItem('tipo');
            localStorage.removeItem('codCliente');
            localStorage.removeItem('session');

            window.location.href = "https://sso.acesso.gov.br/logout";

            if (window.location.href == "https://sso.acesso.gov.br/logout") {
              this.router.navigate(["/index"]);
              window.location.href = "https://dtesantoandre.giss.com.br";
            }

            this.router.navigateByUrl('/index').then(() => {
              window.location.reload();
            });
          }
        });
        return;
      },
    );
    } else {
      if (this.usuarioSemCadastro) {
        swal.fire({
          title: 'Alerta',
          html: 'Este é o seu primeiro acesso. Faz-se necessário validar alguns dados e aderir ao Domicílio Eletrônico para seguir com seu acesso.',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Prosseguir com Adesão',
          showCancelButton: true,
          cancelButtonText: 'Não Prosseguir',
          reverseButtons: true
        }).then((result) => {
          if (result.value && (objContribuinte.cpfCnpj || objContribuinte.cnpj).length === 11) {
            this.router.navigate(['/cep-residencial']);
            localStorage.setItem('objUsuario', JSON.stringify(this.objUsuario));
          }
          if (result.value && (objContribuinte.cpfCnpj || objContribuinte.cnpj).length === 14) {
            localStorage.setItem('pessoaJuridica', JSON.stringify(this.objUsuario));
            this.router.navigate(['/cep-comercial']);
          }
          else if (result.dismiss === swal.DismissReason.cancel) {
            localStorage.removeItem('perfil');
            localStorage.removeItem('dadosGov');
            localStorage.removeItem('procurador');
            localStorage.removeItem('listaCnpjVinculado');
            localStorage.removeItem('credencialIntegracao');
            localStorage.removeItem('contribuinteEmUso');
            localStorage.removeItem('firstVisit');
            localStorage.removeItem('token');
            localStorage.removeItem('tokenCbo');
            localStorage.removeItem('data');
            localStorage.removeItem('tipo');
            localStorage.removeItem('codCliente');
            localStorage.removeItem('session');

            window.location.href = "https://sso.acesso.gov.br/logout";

            if (window.location.href == "https://sso.acesso.gov.br/logout") {
              this.router.navigate(["/index"]);
              window.location.href = "https://dtesantoandre.giss.com.br";
            }

            this.router.navigateByUrl('/index').then(() => {
              window.location.reload();
            });
          }
        });
        return;
      }
      this.validarUsuario().then(isActive => {
        if (!isActive) {
          if ((this.objUsuario.dadoContribuinte.situacao === 1 || this.objUsuario.dadoContribuinte.situacao === 0) && !this.adesaoAtiva) {
            swal.fire({
              title: 'Alerta',
              html: 'Prezado(a), para prosseguir com acesso ao Domicílio Eletrônico do Contribuinte, é necessário aceitar o Termo de Adesão da plataforma.',
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Prosseguir com Adesão',
              showCancelButton: true,
              cancelButtonText: 'Não Prosseguir',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.router.navigate(['/termo-adesao']);
                localStorage.setItem('objUsuario', JSON.stringify(this.objUsuario));
              }
              else if (result.dismiss === swal.DismissReason.cancel) {
                localStorage.removeItem('perfil');
                localStorage.removeItem('dadosGov');
                localStorage.removeItem('procurador');
                localStorage.removeItem('listaCnpjVinculado');
                localStorage.removeItem('credencialIntegracao');
                localStorage.removeItem('contribuinteEmUso');
                localStorage.removeItem('firstVisit');
                localStorage.removeItem('token');
                localStorage.removeItem('tokenCbo');
                localStorage.removeItem('data');
                localStorage.removeItem('tipo');
                localStorage.removeItem('codCliente');
                localStorage.removeItem('session');

                window.location.href = "https://sso.acesso.gov.br/logout";

                if (window.location.href == "https://sso.acesso.gov.br/logout") {
                  this.router.navigate(["/index"]);
                  window.location.href = "https://dtesantoandre.giss.com.br";
                }

                this.router.navigateByUrl('/index').then(() => {
                  window.location.reload();
                });
              }
            });
          }

          if (this.objUsuario.dadoContribuinte.situacao === 2) {
            swal
              .fire(
                getSwal(
                  "error",
                  "Usuário Inativo",
                  "Você já está cadastrado em nosso sistema, mas está com acesso inativo, favor solicitar apoio ao administrador",
                  "ok",
                  null
                )
              )
              .then((res) => {
                localStorage.removeItem('perfil');
                localStorage.removeItem('dadosGov');
                localStorage.removeItem('procurador');
                localStorage.removeItem('listaCnpjVinculado');
                localStorage.removeItem('credencialIntegracao');
                localStorage.removeItem('contribuinteEmUso');
                localStorage.removeItem('firstVisit');
                localStorage.removeItem('token');
                localStorage.removeItem('tokenCbo');
                localStorage.removeItem('data');
                localStorage.removeItem('tipo');
                localStorage.removeItem('codCliente');
                localStorage.removeItem('session');

                window.location.href = "https://sso.acesso.gov.br/logout";

                if (window.location.href == "https://sso.acesso.gov.br/logout") {
                  this.router.navigate(["/index"]);
                  window.location.href = "https://dtesantoandre.giss.com.br";
                }

                this.router.navigateByUrl('/index').then(() => {
                  window.location.reload();
                });
              });
          }

        } else {
          this.loginService.clientes().subscribe(
            res => {
              console.log('loginconfigs >> ', res.resultado);
              let response = res.resultado;

              let contribuinte = response.find(item => item.perfilAcesso.codigo == 2);
              if (contribuinte) {
                this.loginService
                  .obterSessao(contribuinte.codigo)
                  .then(data => {
                    this.userService.sendInfo({
                      loggedIn: true,
                      firstVisit: true,
                      ...data['resultado']
                    });
                    this.router.navigate(['/home']);
                  })
                  .catch(error => {
                    this.handleError(error);
                  });
              }
            },
            error => this.handleError(error)
          );
        }
      });
    }
  }

  escolherPerfil(perfil: string) {
    if (perfil == 'contribuinte') {
      this.obterSessao();
      this.perfil = 'contribuinte';
    }
    this.organizarPaginacao();
  }

  buscarProcuracoes(obj) {
    this.validarUsuario().then(isActive => {
      this.objUsuario = this.objUsuario || {};
      this.objUsuario.dadoContribuinte = this.objUsuario.dadoContribuinte || {};
      this.objUsuario.dadoContribuinte.codigo = this.codUsuario;
      
      if (localStorage.getItem("listaCnpjVinculado") != null) {
        this.haListaProcuracao
      }

      localStorage.setItem('objUsuario', JSON.stringify(this.objUsuario));
      if (!isActive && !this.haListaProcuracao) {
        swal.fire({
          title: 'Alerta',
          html: 'Prezado(a), para prosseguir com acesso ao Domicílio Eletrônico do Contribuinte, é necessário aceitar o Termo de Adesão da plataforma.',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Prosseguir com Adesão',
          showCancelButton: true,
          cancelButtonText: 'Não Prosseguir',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            localStorage.setItem('procurador', 'true');
            this.router.navigate(['/termo-adesao']);
            localStorage.setItem('objUsuario', JSON.stringify(this.objUsuario));
          }
          else if (result.dismiss === swal.DismissReason.cancel) {
            localStorage.removeItem('perfil');
            localStorage.removeItem('dadosGov');
            localStorage.removeItem('procurador');
            localStorage.removeItem('listaCnpjVinculado');
            localStorage.removeItem('credencialIntegracao');
            localStorage.removeItem('contribuinteEmUso');
            localStorage.removeItem('firstVisit');
            localStorage.removeItem('token');
            localStorage.removeItem('tokenCbo');
            localStorage.removeItem('data');
            localStorage.removeItem('tipo');
            localStorage.removeItem('codCliente');
            localStorage.removeItem('session');

            window.location.href = "https://sso.acesso.gov.br/logout";

            if (window.location.href == "https://sso.acesso.gov.br/logout") {
              this.router.navigate(["/index"]);
              window.location.href = "https://dtesantoandre.giss.com.br";
            }

            this.router.navigateByUrl('/index').then(() => {
              window.location.reload();
            });
          }
        });
      } else {
        localStorage.setItem('firstVisit', 'true');
        this.loginService.clientes().subscribe(
          res => {
            console.log('loginconfigs >> ', res.resultado);
            let response = res.resultado;
            response = res.resultado.find(item => item.perfilAcesso.codigo === 3);
            if (response) {
              this.loginService
                .obterSessao(response.codigo)
                .then(data => {
                  this.userService.sendInfo({
                    loggedIn: false,
                    ...data['resultado']
                  });
                  this.router.navigate(['/procuracoes']);
                })
                .catch(error => {
                  this.handleError(error);
                });
            } else {
              swal.fire(
                getSwal(
                  'error',
                  'Ops!',
                  'Seu perfil não possui procurações outorgadas.',
                  'ok',
                  null
                )
              ).then(res => {
                // this.router.navigate(['/index']);
              });
            }
          },
          error => this.handleError(error)
        );
      }
    });
  }

  obterTermoProcuracao() {
    localStorage.getItem('token');
    localStorage.setItem('firstVisit', 'true');

    this.cadastroService.clientes().subscribe(res => {
      this.codigoCliente = res.resultado[0].codigo;
      // this.termoProcuracaoOriginal = res.resultado[0].configuracao.termoProcuracao.texto;
      this.exigeTermoProcuracao = res.resultado[0].configuracao.exigeTermoProcuracao;
    })
    // this.router.navigate(['/procuracoes']);
  }

  compareCodigo(a, b) {
    if (a.codigo > b.codigo) {
      return -1;
    }
    if (a.codigo < b.codigo) {
      return 1;
    }
    return -1;
  }

  handleResponse(res: any) {
    if (res['resultado'].length === 0) {
      this.showPage = true;

      swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Seu perfil não possui procurações outorgadas.',
          'ok',
          null
        )
      ).then(res => {
        // this.router.navigate(['/index']);
      });
    } else if (
      res['resultado'].length === 1 &&
      res['resultado'][0].situacao.descricao === 'Válida'
    ) {
      window.localStorage.setItem(
        'hasletters',
        (res['resultado'].length === 1).toString()
      );
      this.procuracoes = res['resultado'];
      this.procuracoes = this.procuracoes.sort(this.compareCodigo);
      this.procuracoes.forEach(elem => {
        // procuracoesTable
        console.log('elem:', elem);
        elem['selected'] = false;
        if (
          elem['outorgante']['detalhe'] &&
          elem['outorgante']['detalhe'] !== null
        ) {
          elem['outorgante'].inscricao =
            elem['outorgante']['detalhe']['inscricaoMunicipal'] !== null
              ? elem['outorgante']['detalhe']['inscricaoMunicipal']
              : 'Não Informado';
        }

        elem['nomeRazaoSocial'] = elem.outorgante.nomeRazaoSocial;
        elem['inscricao'] = elem.outorgante.inscricao;
        elem['tipoProcuracaoOrder'] = elem.tipoProcuracao.descricao;
        elem['situacaoOrder'] = elem.situacao.descricao;
        this.procuracoesTable.push(elem);

      });
      this.procuracao = res['resultado'][0];
      this.proximo();
    } else {
      window.localStorage.setItem(
        'hasletters',
        (res['resultado'].length > 1).toString()
      );
      this.showPage = true;
      const procuracoes = res['resultado'];
      this.procuracoes = procuracoes.sort(this.compareCodigo);

      this.procuracoes.map(procuracao => {
        if (procuracao.situacao.descricao !== 'Válida') {
          procuracao['inactive'] = true;
        }
      });
      this.procuracoes.forEach(elem => {

        elem['selected'] = false;
        if (
          elem['outorgante']['detalhe'] &&
          elem['outorgante']['detalhe'] !== null
        ) {
          elem['outorgante'].inscricao =
            elem['outorgante']['detalhe']['inscricaoMunicipal'] !== null
              ? elem['outorgante']['detalhe']['inscricaoMunicipal']
              : 'Não Informado';
        }

        elem['nomeRazaoSocial'] = elem.outorgante.nomeRazaoSocial;
        elem['inscricao'] = elem.outorgante.inscricao;
        elem['tipoProcuracaoOrder'] = elem.tipoProcuracao.descricao;
        elem['situacaoOrder'] = elem.situacao.descricao;
        this.procuracoesTable.push(elem);

      });
    }
  }

  proximo() {
    let codigoProcuracao = this.procuracao['codigo'];
    if (this.userInfo['procuracao'] === null) {
      this.loginService
        .atualizarSessao(codigoProcuracao)
        .then(res => {
          this.userService.sendInfo({
            loggedIn: true,
            qtdProcuracoes: this.procuracoes.length,
            ...res['resultado']
          });
          this.router.navigate(['/home']);
        })
        .catch(error => {
          this.router.navigate(['/']);
        });
    } else {
      this.loginService.trocarProcuracao(codigoProcuracao).subscribe(
        res => {
          this.userService.sendInfo({ loggedIn: true, ...res['resultado'] });
          this.router.navigate(['/home']);
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  handleError(error: any) {
    console.error(error);
    swal.fire(
      getSwal(
        'error',
        'Ops!',
        'Seu perfil não possui procurações outorgadas.',
        'ok',
        null
      )
    ).then(res => {
      this.router.navigate(['/index']);
    });
  }


  organizarPaginacao() {
    this.listaPaginada = [];
    if (this.perfil == 'contribuinte') {
      this.quantidadePaginas = this.listaContribuintes.length / 10;
      this.quantidadePaginas = Math.trunc(this.quantidadePaginas);
      if (this.listaContribuintes.length % 10 > 0) {
        this.quantidadePaginas += 1;
      }
      this.listaPaginada = [];
      this.listaPaginada = this.listaContribuintes.slice((this.pagina - 1) * 10, this.quantidadePorPagina * this.pagina);
    }
  }

  aplicarMascaraCPF_CNPJ(valor) {
    const cpfCnpjLimpo = valor.replace(/\D/g, '');
    if (cpfCnpjLimpo.length === 11) {
      return cpfCnpjLimpo.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cpfCnpjLimpo.length === 14) {
      return cpfCnpjLimpo.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    } else {
      return valor;
    }
  }

  validarPerfil() {
    if (this.listaContribuintes) {
      return
    } else if (this.listaContribuintes) {
      if (this.listaContribuintes.length == 1) {
        localStorage.setItem('contribuinteEmUso', JSON.stringify(this.listaContribuintes[0]));
        this.router.navigate(['/inicio']);
        return
      }
      this.escolherPerfil('contribuinte');
      this.retornarDesativado = true;
    } else {
      this.router.navigate(['/inicio']);
    }
  }

  primeiraPagina() {
    this.pagina = 1;
    this.organizarPaginacao();
  }

  proximaPagina() {
    this.pagina += 1;
    this.organizarPaginacao();
  }

  paginaAnterior() {
    this.pagina -= 1;
    this.organizarPaginacao();
  }

  ultimaPagina() {
    this.pagina = this.quantidadePaginas;
    this.organizarPaginacao();
  }

  voltarSelecao() {
    this.pagina = 1;
    this.listaPaginada = [];
    this.listaContribuintes = [];
    this.perfil = '';
  }

}
