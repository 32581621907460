import { Component, OnInit, Input, Output } from '@angular/core';
import { WizardService } from '../../service/wizard/wizard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { CadastroService } from '../../service/cadastro/cadastro.service';
import moment from 'moment-timezone';

import { getSwal } from '../../utils/swalObject';
import swal from 'sweetalert2';
import { trigger, transition, style, animate } from '@angular/animations';
import { Valida } from 'src/app/form-utils/valida';
import { IntegracaoService } from 'src/app/service/Integracao/integracao.service';
import { UserService } from 'src/app/service/user-service/user.service';
import { LoginService } from 'src/app/service/login/login.service';
import { ConsultaUsuarioService } from 'src/app/service/consulta-usuario/consultar.user.service';

@Component({
  selector: '[app-termoDeAdesao]',
  templateUrl: 'termoDeAdesao.component.html',
  styleUrls: ['./termoDeAdesao.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
    ]),
  ],
})
export class termoDeAdesaoComponent implements OnInit {
  perfil: string = '';
  index: number = 4;
  data = {};
  infoBasica = null;
  repLegal = null;
  procurador = null;
  prefeituras = null;
  tipo = null;
  semOutorga: boolean = false;
  loading: boolean = false;
  id: number = null;
  pessoa: any;
  atribuir = false;
  termoAdesao: any;
  termoAdesaoTexto: any;
  exigeTermoAdesao: boolean = false;
  liConcordo: boolean = false;
  dataCurta: any = moment(new Date()).format('DD/MM/YYYY');
  dataLonga: any = moment().format('LL');
  cpfRepresentanteLegal: any;
  nomeRepresentanteLegal: string

  codUsuario: any;
  codUsuarioGovCadastrado: any;
  codTermoAdesao: any;
  code: string;
  state: string;
  cadastroGovBR: boolean = false;
  adesaoAtiva: boolean = false;
  usuarioGovBR: any;

  aceiteGovBr: boolean = false;
  aceiteDte: boolean = false;
  haListaProcuracao: boolean = false
  procuradorSelecionado: any;
  dadosCnpj: any;

  constructor(
    private wizardService: WizardService,
    private router: Router,
    private cadastroService: CadastroService,
    private integracaoService: IntegracaoService,
    private userService: UserService,
    private loginService: LoginService,
    private consultaUsuario: ConsultaUsuarioService,
  ) {

    this.wizardService.updateIndex(this.index);
  }

  ngOnInit() {
    // this.buscarProcuracoesAtivas();
    this.validarUsuario();

    localStorage.getItem('token');
    let dados = JSON.parse(localStorage.getItem('objUsuario'));
    let dadoscnpj = JSON.parse(localStorage.getItem('codUsuarioCnpj'));
    this.procuradorSelecionado = JSON.parse(localStorage.getItem('procurador'));
    let codAdesao = JSON.parse(localStorage.getItem('prefeituras'));
    this.codTermoAdesao = codAdesao.configuracao.termoAdesao.codigo;
    this.codUsuario = dados.dadoContribuinte && dados.dadoContribuinte.codigo || dadoscnpj && dadoscnpj.codigo;

    this.cadastroService.clientes().subscribe(
      res => {
        this.termoAdesaoTexto = res.resultado[0].configuracao.termoAdesao.texto
          .replace(/@{id do contribuinte}/g, this.wizardService.userData.infoBasica['cnpjContribuinte'])
          .replace(/@{contribuinte}/g, this.wizardService.userData.infoBasica['razaoSocialContribuinte'])
          .replace(/@{id do representante}/g, this.cpfRepresentanteLegal)
          .replace(/@{representante}/g, this.nomeRepresentanteLegal)
          .replace(/@{data curta}/g, this.dataCurta)
          .replace(/@{data longa}/g, this.dataLonga)
        this.exigeTermoAdesao = res.resultado[0].configuracao.exigeTermoAdesao
      }
    )
  }
  
  obterSessao() {
    localStorage.setItem('firstVisit', 'true');
    this.loginService.clientes().subscribe(
      res => {
        console.log('obterSessao >> ', res.resultado);
        let response = res.resultado;
        for (let i = 0; i < response.length; i++) {
          if (response[i].perfilAcesso && response[i].perfilAcesso.codigo == 2 && response[i].usuario) {
            let contribuinte = {
              cpfCnpj: response[i].usuario.cpfCnpj,
              dscRazaoSocial: response[i].usuario.nomeRazaoSocial,
              dadoContribuinte: response[i].usuario,
            };
          }
        }
      },
      error => {
        let contribuinte = {
          dscRazaoSocial: this.usuarioGovBR.usuarioGov.nomeCompleto,
          cpfCnpj: null,
          dadoContribuinte: 'valor padrão',
        };

        if (this.usuarioGovBR.usuarioGov) {
          if (this.usuarioGovBR.usuarioGov.cpf) {
            contribuinte.cpfCnpj = this.usuarioGovBR.usuarioGov.cpf;
          } else {
            contribuinte.cpfCnpj = this.usuarioGovBR.usuarioGov.cnpj;
          }
        }

        // this.usuarioSemCadas;tro = true;
      }
    );
  }

  validarUsuario() {
    localStorage.getItem('token');
    localStorage.setItem('firstVisit', 'true');

    this.usuarioGovBR = JSON.parse(localStorage.getItem("dadosGov"));

    if (this.usuarioGovBR == null) {
      this.adesaoAtiva = false;
      return;
    }

    if (this.usuarioGovBR.cadastradoDte == false && this.usuarioGovBR.usuario == null) {
      this.adesaoAtiva = false;
      return;
    }


    this.loginService.clientes().subscribe(
      res => {
        console.log('loginconfigs >> ', res.resultado);
        let response = res.resultado;

        if (response.length <= 1) {
          response = res.resultado[0];
          this.loginService
            .obterSessao(response.codigo)
            .then(data => {
              this.userService.sendInfo({
                loggedIn: true,
                firstVisit: true,
                ...data['resultado']
              });
              this.router.navigate(['/home']);
            })
            .catch(error => {
              this.handleError(error);
            });
        }
      },
      error => this.handleError(error)
    );




    return new Promise((resolve, reject) => {
      this.codUsuarioGovCadastrado = localStorage.getItem('codUsuarioCadastrado');
      if (this.codUsuarioGovCadastrado) {
        this.codUsuario = this.codUsuarioGovCadastrado;
      }
      this.consultaUsuario.validarAdesaoAtiva(this.codUsuario, this.codTermoAdesao).subscribe(
        res => {
          if (res['resultado'] == false) {
            this.adesaoAtiva = false;
            resolve(false);
          } else {
            resolve(true);
            this.adesaoAtiva = true;
            this.loginConfigs(res)
          }
        },
        error => {
          console.error('Erro ao validar adesão', error);
          reject({ message: 'Erro ao validar adesão' });
        }
      );
    });
  }

  onCheckboxChange() {
    if (this.liConcordo) {
      this.aceitarTermo();
    } else {
      this.aceiteGovBr = false;
      this.aceiteDte = false;
    }
  }


  aceitarTermo() {
    this.codUsuarioGovCadastrado = localStorage.getItem('codUsuarioCadastrado');
    if (this.codUsuarioGovCadastrado) {
      this.codUsuario = this.codUsuarioGovCadastrado;
      this.aceiteGovBr = true
      return;
    } else {
      console.log('usuario adesao', this.codUsuario)
      console.log('termo adesao', this.codTermoAdesao)
      this.aceiteDte = true
    }
  }

  prosseguir() {
    if (this.aceiteGovBr == true) {
      this.codUsuarioGovCadastrado = localStorage.getItem('codUsuarioCadastrado');
      if (this.codUsuarioGovCadastrado) {
        this.codUsuario = this.codUsuarioGovCadastrado;

        this.cadastroService
          .aceitarTermoAdesao(this.codUsuario, this.codTermoAdesao)
          .subscribe(
            res => {
              this.handleResponse(res);
              this.router.navigate(["/index"]);
            },
            error => this.handleError(error),
          );
        return;
      }
    } else if (this.aceiteDte == true) {
      console.log('usuario adesao', this.codUsuario)
      console.log('termo adesao', this.codTermoAdesao)
      this.cadastroService
      console.log('aceitouu2')

      this.cadastroService
      .aceitarTermoAdesao(this.codUsuario, this.codTermoAdesao)
      .subscribe(
        res => {
          this.handleResponse(res);
          localStorage.getItem('token');
          localStorage.setItem('firstVisit', 'true');
          this.loginService.clientes().subscribe(
            res => {
              console.log('loginconfigs >> ', res.resultado);
              let response = res.resultado;

              let contribuinte = response.find(item => item.perfilAcesso.codigo == 2);
              if (contribuinte) {
                this.loginService
                  .obterSessao(contribuinte.codigo)
                  .then(data => {
                    this.userService.sendInfo({
                      loggedIn: true,
                      firstVisit: true,
                      ...data['resultado']
                    });
                    this.router.navigate(['/home']);
                  })
                  .catch(error => {
                    this.handleError(error);
                  });
              }
            },
            error => this.handleError(error)
          );
      this.loginService.clientes().subscribe(
        res => {
          console.log('loginconfigs >> ', res.resultado);
          let response = res.resultado;
          if (response.length <= 1) {
            response = res.resultado[0];
            this.loginService
              .obterSessao(response.codigo)
              .then(data => {
                if (data['resultado'].perfilAcesso.codigo !== 3) {
                  this.userService.sendInfo({
                    loggedIn: true,
                    firstVisit: true,
                    ...data['resultado'],
                  });
                  this.router.navigate(['/home']);
                } else {
                  this.userService.sendInfo({
                    loggedIn: false,
                    ...data['resultado'],
                  });
                  // this.router.navigate(['/procuracoes']);
                }
              })
              .catch(error => this.handleError(error));
          }
          // else {
          //   this.router.navigate(['perfis']);
          // }
          else {
            let response = res.resultado;
            response = res.resultado.find(item => item.perfilAcesso.codigo === 3);
            if (response && this.procuradorSelecionado) {
              this.loginService
                .obterSessao(response.codigo)
                .then(data => {
                  if (data['resultado'].perfilAcesso.codigo !== 3) {
                    this.userService.sendInfo({
                      loggedIn: true,
                      firstVisit: true,
                      ...data['resultado'],
                    });
                    this.router.navigate(['/home']);
                  } else {
                    this.userService.sendInfo({
                      loggedIn: false,
                      ...data['resultado'],
                    });
                    this.router.navigate(['/procuracoes']);
                  }
                }
                )
            } else {
              localStorage.setItem('firstVisit', 'true');
              this.loginService.clientes().subscribe(
                res => {
                  console.log('loginconfigs >> ', res.resultado);
                  let response = res.resultado;
                  response = res.resultado[0];
                  this.loginService
                    .obterSessao(response.codigo)
                    .then(data => {
                      this.userService.sendInfo({
                        loggedIn: true,
                        firstVisit: true,
                        ...data['resultado']
                      });
                      this.router.navigate(['/home']);
                    })
                    .catch(error => {
                      this.handleError(error);
                    });
                },
                error => this.handleError(error)
              );
            }
          }
        },
        error => this.handleError(error)
      );
        })
    }
  }

  naoProsseguir() {
    localStorage.removeItem('perfil');
    localStorage.removeItem('dadosGov');
    localStorage.removeItem('listaCnpjVinculado');
    localStorage.removeItem('procurador');
    localStorage.removeItem('objUsuario');
    localStorage.removeItem('credencialIntegracao');
    localStorage.removeItem('contribuinteEmUso');
    localStorage.removeItem('firstVisit');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenCbo');
    localStorage.removeItem('data');
    localStorage.removeItem('tipo');
    localStorage.removeItem('codCliente');
    localStorage.removeItem('session');

    window.location.href = "https://sso.acesso.gov.br/logout";

    if (window.location.href == "https://sso.acesso.gov.br/logout") {
      this.router.navigate(["/index"]);
      window.location.href = "https://dtesantoandre.giss.com.br";
    }

    this.router.navigateByUrl('/index').then(() => {
      window.location.reload();
    });
  }

  loginConfigs(obj) {
    localStorage.setItem('firstVisit', 'true');
    this.loginService.clientes().subscribe(
      res => {
        console.log('loginconfigs >> ', res.resultado);
        let response = res.resultado;

        if (response.length <= 1) {
          response = res.resultado[0];
          this.loginService
            .obterSessao(response.codigo)
            .then(data => {
              this.userService.sendInfo({
                loggedIn: true,
                firstVisit: true,
                ...data['resultado']
              });
              this.router.navigate(['/home']);
            })
            .catch(error => {
              this.handleError(error);
            });
        }
      },
      error => this.handleError(error)
    );
  }

  handleErrors(error) {
    swal.fire(
      getSwal(
        'warning',
        'Erro na Integração GOV.BR',
        error,
        'ok',
        null,
      ),
    ).then(res => {
      this.loading = false;
      this.router.navigate(['/index']);
    });
  }

  handleResponse(res) {
    this.loading = false;
    this.wizardService.resetData();
    this.wizardService.userData.prefeituras = this.prefeituras;
    this.wizardService.userData['codigo'] = res.resultado;
    this.wizardService.userData['id'] = this.id;
    this.wizardService.userData['perfil'] = this.perfil;
    if (this.atribuir === true) {
      swal
        .fire(
          getSwal(
            "success",
            "Usuário cadastrado",
            "Foi adicionado o perfil Contribuinte ao seu cadastro. Use a mesma senha já cadastrada para ter acesso aos seus perfis de usuário.",
            "ok",
            null
          )
        )
        .then((res) => {
          this.router.navigate(["termo-adesao"]);
        });
    }
  }

  handleError(error) {
    this.loading = false;
    this.wizardService.resetData();

    swal.fire(
      getSwal(
        'error',
        'Ops!',
        'Ocorreu um erro e a adesão não pôde ser ativada',
        'ok',
        null,
      ),
    ).then(res => {
      // localStorage.removeItem('perfil');
      // localStorage.removeItem('dadosGov');
      // localStorage.removeItem('listaCnpjVinculado');
      // localStorage.removeItem('procurador');
      // localStorage.removeItem('credencialIntegracao');
      // localStorage.removeItem('contribuinteEmUso');
      // localStorage.removeItem('firstVisit');
      // localStorage.removeItem('token');
      // localStorage.removeItem('tokenCbo');
      // localStorage.removeItem('data');
      // localStorage.removeItem('tipo');
      // localStorage.removeItem('codCliente');
      // localStorage.removeItem('session');

    //   window.location.href = "https://sso.acesso.gov.br/logout";

    //   if (window.location.href == "https://sso.acesso.gov.br/logout") {
    //     this.router.navigate(["/index"]);
    //     window.location.href = "https://dtesantoandre.giss.com.br";
    //   }

    //   this.router.navigateByUrl('/index').then(() => {
    //     window.location.reload();
    //   });
    });
  }
}
