import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, MinLengthValidator } from '@angular/forms';
import { trigger, transition, animate, style, keyframes } from '@angular/animations'
import { ActivatedRoute, Router } from '@angular/router';
import { WizardService } from '../../../../service/wizard/wizard.service';
import { Subscription } from 'rxjs';
import { CadastroService } from '../../../../service/cadastro/cadastro.service';
import { RecuperarSenhaService } from './../../../../service/recuperar-senha/recuperar-senha.service';
import swal from 'sweetalert2';
import { getSwal } from "../../../../utils/swalObject";

@Component({
  selector: 'app-codigo',
  templateUrl: 'codigo.component.html',
  styleUrls: ['./codigo.component.scss'],
  animations: [
    trigger('shake', [
      transition(':enter', animate(400, keyframes([
        style({ transform: 'translate(30px)', offset: .0 }),
        style({ transform: 'translate(-30px)', offset: .20 }),
        style({ transform: 'translate(15px)', offset: .40 }),
        style({ transform: 'translate(-15px)', offset: .60 }),
        style({ transform: 'translate(8px)', offset: .80 }),
        style({ transform: 'translate(0px)', offset: 1 }),
      ]))),
    ])
  ]
})

export class CodigoComponent implements OnInit {

  codeForm: FormGroup;
  perfil = ''
  codigo: number = null;
  routeSub: Subscription;
  loading: boolean = false;
  hasError: boolean = false;
  errorMsg: string = ''
  pessoaFisica = false;
  servicoSms: number;
  codigoUsuario: number;
  userData: Object = {};
  data = {};
  infoBasica = null;
  GROUP_SEPARATOR = ',';
  errorMessage: string = '';

  constructor(
    private fb: FormBuilder,
    private wizardService: WizardService,
    private router: Router,
    private route: ActivatedRoute,
    private recuperarSenhaService: RecuperarSenhaService,
    private cadastroService: CadastroService) {
    this.wizardService.updateIndex(6);
  }

  ngOnInit() {
    let dados = JSON.parse(localStorage.getItem('objUsuario'));
    // this.servicoSms = this.wizardService.userData.prefeituras[0]['configuracao']['servicoSms'];
    if (this.router.url.split('/')[3] === 'codigo-fisica') {
      this.pessoaFisica = true;
    } else {
      this.pessoaFisica = false;
    }
    this.criarFormulario();
    // this.codigo = this.wizardService.userData['codigo'];
    this.codigo = dados.dadoContribuinte.codigo;
    this.perfil = this.wizardService.userData.perfil;  

  }

  criarFormulario() {
    this.codeForm = this.fb.group({
      code: ['', Validators.required]
    });
  }

  proximo(submitBtn: HTMLButtonElement) {
    this.loading = true;
    submitBtn.disabled = true;
    let codigoValidacao = this.codeForm.controls.code.value;
    // if (this.perfil === 'contribuinte') {
      this.cadastroService.validarUsuario(this.codigo, codigoValidacao, 'validarContribuinte').subscribe(res => {
        this.loading = false;
        submitBtn.disabled = false;
        this.router.navigate(['cadastro/contribuinte/senha'])
      }, error => this.handleError(error))
    // }
    // else {
    //   this.cadastroService.validarUsuario(this.codigo, codigoValidacao, 'validarProcurador').subscribe(res => {
    //     this.loading = false;
    //     submitBtn.disabled = false;
    //     this.router.navigate(['cadastro/procurador/senha'])
    //   }, error => this.handleError(error))
    // }
  }

  handleError(err) {
    let error = err["error"]
    this.loading = false;
    this.hasError = true;
    this.errorMsg = error.excecao.recomendacao;
    this.codeForm.reset();
  }

  unFormat(val) {
    if (!val) {
      return '';
    }
    val = val.replace(/\D/g, '');

    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
  }

  reenvioCodigo(){
    let infoBasica = {
      cpf: sessionStorage.getItem('cpf'),
      ddd: sessionStorage.getItem('dddTelefoneCelular'),
      celular: sessionStorage.getItem('telefoneCelular'),
      email: sessionStorage.getItem('email')
    }
   console.log(infoBasica);

    let credentials = Object.assign({}, infoBasica);
    credentials['cpf'] = this.unFormat(credentials['cpf']);
    credentials['ddd'] = credentials['ddd'];
    credentials['celular'] = credentials['celular'];
    credentials['email'] = credentials['email'];
    console.table(credentials);
    this.recuperarSenhaService 
      .verificarUsuario(credentials)
      .subscribe(res => this.handleRes(res), error => this.handleError(error));
  }

  handleRes(res){
    this.userData = res.resultado;
    this.codigoUsuario = res.resultado.codigo;
    if(this.servicoSms == 0) {
      let codigo = this.userData['codigo'];
      this.recuperarSenhaService
        .recuperarSenha(codigo, '/solicitarValidacaoPorEmail')
        .subscribe(
          res => {
          },
          error => this.handleErr(),
        );
    } else if(this.servicoSms > 0){
      let codigo = this.userData['codigo'];
      this.recuperarSenhaService
        .recuperarSenha(codigo, '/solicitarValidacaoPorSms')
        .subscribe(
          res => {
          },
          error => this.handleErr(),
        );
    }
  }

  handleErr() {
    swal.fire(
      getSwal(
        'error',
        'Ops',
        'Ocorreu um erro e não foi possível reenviar o código de verificação',
        'ok',
        null,
      ),
    )
  }

  codigoMask(codigoValidacao){
    codigoValidacao.value.replace(/\D+/g, "");
 } 

}