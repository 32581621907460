import { animate, style, transition, trigger } from "@angular/animations";

export const fadeAnimation = trigger('fade', [
  transition(':enter', [
    style({
      opacity: 1
    }),
    animate(300, style({
      opacity: 1
    }))
  ]),
  transition(':leave', [
    animate(300, style({
      opacity: 0
    }))
  ])
]);
