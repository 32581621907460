import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WizardService } from '../../service/wizard/wizard.service';
import { Subscription } from 'rxjs/Subscription';
import { CadastroService } from '../../service/cadastro/cadastro.service';

@Component({
  selector: 'app-cadastro',
  templateUrl: 'auto-cadastro.component.html',
  styleUrls: ['./auto-cadastro.component.scss'],
})
export class AutoCadastroComponent implements OnInit {
  subscrition: Subscription;
  routeSub: Subscription;
  active: number = 1;
  showWizard: boolean = true;
  perfil: string = '';
  wizardTotalSteps: number = 4;

  dialogMessage = {};

  constructor(
    private wizardService: WizardService,
    private cadastroService: CadastroService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.registerWizard();
    // this.getPrefeituras();
    this.wizardTotalSteps =
      this.wizardService.userData.prefeituras.length === 1 ? 3 : 4;
    this.routeSub = this.route.params.subscribe(params => {
      this.perfil = params['perfil'];
    });
  }

  getShowWizard() {
    // return this.showWizard;
  }

  registerWizard() {
    return new Promise((resolve, reject) => {
      this.wizardService.index$.subscribe(index => {
        this.active = index;
        if (this.active <= this.wizardTotalSteps) {
          this.showWizard = true;
        } else {
          this.showWizard = false;
        }
      });
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
