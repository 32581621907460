// import { DesempenhoService } from './../../../service/desempenho/desempenho.service';
import { Location, ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fadeAnimation } from 'src/app/animations';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { DesempenhoService } from 'src/app/service/desempenho/desempenho.service';
import { getSwal } from 'src/app/utils/swalObject';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  animations: [fadeAnimation]
})

export class IndexComponent implements OnInit {

  customClass!: {
    container: 'container-class';
    popup: 'popup-class';
    header: 'header-class';
    title: 'title-class';
    closeButton: 'close-button-class';
    icon: 'icon-class';
    image: 'image-class';
    content: 'content-class';
    input: 'input-class';
    actions: 'actions-class';
    confirmButton: 'confirm-button-class';
    cancelButton: 'cancel-button-class';
    footer: 'footer-class';
  };
  faUser = faUser;
  desempenho!: any;
  quantidadeDesempenho: number = 0;
  exibirAcessibilidade: boolean = false;
  loading: boolean = true;
  exigeCertificadoCpf: boolean = false;
  exigeCertificadoCNPJ: boolean = false;
  dadosPortal: number;
  idPerfilAcesso: any;

  characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  randomState: string = '';
  randomNonce: string = '';
  urlIntegracao: string = '';

  recarregamento: boolean = false;

  constructor(
    private viewportScroller: ViewportScroller,
    private cadastroService: CadastroService,
    private desempenhoService: DesempenhoService,
    private router: Router,
    private location: Location
  ) {
    this.limparDados();
    if (!localStorage.getItem('recarregamento')) {
      localStorage.setItem('recarregamento', 'true');
      window.location.reload();
    } else {
      localStorage.removeItem('recarregamento');
    }
  }

  ngOnInit(): void {
    this.obterCliente();
    this.getAllDatas();
  }

  redirecionarGovBr() {
    // let dev = 'https%3A%2F%2Fdtesantoandre%2Edev%2Egiss%2Ecom%2Ebr/';
    let prod = 'https%3A%2F%2Fdtesantoandre%2Egiss%2Ecom%2Ebr/'
    let nonce = (Math.random() + 1).toString(36).substring(7);
    let state = (Math.random() + 1).toString(36).substring(7);
    let urlIntegracao = "https://sso.acesso.gov.br/authorize?response_type=code&client_id=dtesantoandre.giss.com.br&scope=openid+email+phone+profile+govbr_empresa+govbr_confiabilidades&redirect_uri=" + prod + "&nonce=" + nonce + "&state=" + state;
    window.location.href = urlIntegracao;
  }

  async getAllDatas() {
    const promisses = [
      this.getDesempenho(),
      this.getConfiguracaoExigeCertificadoCPF(),
      this.getConfiguracaoClienteExigeCertificado()
    ]

    await Promise.all(promisses).then((res) => {
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });

  }

  limparDados() {
    localStorage.removeItem('perfil');
    localStorage.removeItem('dadosGov');
    localStorage.removeItem('listaCnpjVinculado');
    localStorage.removeItem('procurador');
    localStorage.removeItem('objUsuario');
    localStorage.removeItem('credencialIntegracao');
    localStorage.removeItem('contribuinteEmUso');
    localStorage.removeItem('firstVisit');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenCbo');
    localStorage.removeItem('data');
    localStorage.removeItem('tipo');
    localStorage.removeItem('codCliente');
    localStorage.removeItem('session');
    localStorage.removeItem('codUsuarioCadastrado');
  }

  redirecionar() {
    this.router.navigate(["inicio"])
  }

  obterCliente() {
    this.cadastroService.clientes().subscribe(
      res => {
        console.log("res", res)
        this.idPerfilAcesso = res.resultado['0'].codigo
        console.log(this.idPerfilAcesso)
      },
      error => {

      },
    );
  }

  getDesempenho() {
    this.desempenhoService.getDesempenho().subscribe((data) => {
      this.desempenho = {
        comunicadosTrocados: data.comunicadosTrocados,
        contribuintesAtivos: data.contribuintesAtivos,
        dados: data.dados,
        procuradoresAtivos: data.procuradoresAtivos
      }
      console.log('desempenho: ', this.desempenho)
    });
  }

  getConfiguracaoClienteExigeCertificado() {
    return new Promise((resolve, reject) => {
      this.cadastroService.getConfiguracaoClienteExigeCertificado().subscribe(
        res => {
          this.exigeCertificadoCNPJ = res.resultado;
          resolve(this.exigeCertificadoCNPJ);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  getConfiguracaoExigeCertificadoCPF() {
    return new Promise((resolve, reject) => {
      this.cadastroService.getConfiguracaoClienteExigeCertificadoCPF().subscribe(
        res => {
          this.exigeCertificadoCpf = res.resultado;
          resolve(this.exigeCertificadoCpf);
        },
        error => {
          reject(error);
        });
    })
  }

  exigeCertificado(profile: any) {
    if (this.exigeCertificadoCNPJ) {
      Swal.fire(
        getSwal(
          'warning',
          'Exige Certificado',
          'Para fazer cadastro de Pessoa Jurídica, é exigido certificado digital padrão ICP-Brasil válido. Será exigido o certificado ao clicar em Prosseguir, e pode ser que seja redirecionado para tela de configuração de componente para leitura do certificado.',
          'Prosseguir',
          'Desistir'
        )
      ).then(res => {
        if (res.value) {
          setTimeout(() => {
            this.router.navigate(['/login/certificado-digital', profile]);
          }, 150);
        } else {
          setTimeout(() => {
            this.router.navigate(['/index']);
          }, 150);
        }
      });
    } else {
      this.router.navigate([`/cadastro/${profile}-juridico`]);
    }
  }

  exigeCertificadoProcuradorFisico(profile: string) {
    if (this.exigeCertificadoCpf) {
      Swal.fire(
        getSwal(
          'warning',
          'Exige Certificado',
          'Para fazer cadastro de Procurador Pessoa Física, é exigido certificado digital padrão ICP-Brasil válido. Será exigido o certificado ao clicar em Prosseguir, e pode ser que seja redirecionado para a tela de configuração de componente para leitura do certificado.',
          'Prosseguir',
          'Desistir'
        )
      ).then(res => {
        if (res.value) {
          setTimeout(() => {
            this.router.navigate(['/login/certificado-digital', profile]);
          }, 150);
        } else {
          setTimeout(() => {
            this.router.navigate(['/index']);
          }, 150);
        }
      });
    } else {
      this.router.navigate([`/cadastro/${profile}-fisico`]);
    }
  }

}
