import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared.module';
import { IntegracaoComponent } from './features/integracao/integracao.component';
import { IndexModule } from './features/index/index.module';
import { LoginModule } from './features/login/login.module';
import { HomeModule } from './features/home/home.module';
import { CommonAppModule } from './features/common/common.module';
import { RegistroAcoesModule } from './features/registro-acoes/registro-acoes.module';
import { CadastroModule } from './features/auto-cadastro/auto-cadastro.module';
import { ProcuracaoModule } from './features/procuracao/procuracao.module';
import { CaixaPostalEletronicaModule } from './features/caixa-postal-eletronica/caixa-postal-eletronica.module';
import { AppRoutingModule } from './app.routing.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { PerfilUserModule } from './features/perfil/perfil-user.module';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';

import { AsyncPipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import {
  TranslateService,
  TranslateModule,
  TranslateLoader
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { I18nConfiguration } from './utils/i18n.config';
import { I18nInitializer } from './utils/i18n.initializer';
import { environment } from '../environments/environment.prod';
import { SvgInitializer } from './utils/svg.initializer';
import { HealthcheckComponent } from './features/healthcheck/healthcheck.component';

import { SimpleNotificationsModule } from 'angular2-notifications';
import { MessagingService } from './service/push-notification/messaging.service';

registerLocaleData(localePt);

/**
 * Factory para carregamento dos arquivos de i18n
 * @param httpClient
 */
export function HttpLoaderFactory(httpClient: HttpClient) {
  // return new TranslateHttpLoader(httpClient);
  return new TranslateHttpLoader(httpClient, environment.i18nPath, '.json');
}

export class svgEndpoint {
  getConfig() {
    return { svg_sprites: [`${environment.assetsPath}images/sprite.svg`] };
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HealthcheckComponent,
    IntegracaoComponent
  ],
  imports: [
    BrowserModule,
    CaixaPostalEletronicaModule,
    CommonAppModule,
    InlineSVGModule,
    LoginModule,
    HomeModule,
    IndexModule,
    CadastroModule,
    SharedModule,
    ProcuracaoModule,
    FontAwesomeModule,
    RouterModule,
    RegistroAcoesModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PerfilUserModule,
    ReactiveFormsModule,
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    SimpleNotificationsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    // { provide: LOCALE_ID, useValue: 'pt-BR' },
    HttpClient,
    TranslateService,
    I18nConfiguration,
    MessagingService,
    AsyncPipe,
    svgEndpoint,
    //{ provide: ErrorHandler, useClass: EiconErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: I18nInitializer,
      deps: [TranslateService, I18nConfiguration],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: SvgInitializer,
      deps: [HttpClient, svgEndpoint],
      multi: true
    }
  ]
})
export class AppModule { }
