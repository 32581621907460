import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

import { environment } from '../../../environments/environment'
import { map } from 'rxjs/operators';


@Injectable()
export class ConsultaUsuarioService {

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for(const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  consultarUsuario(id: string): Observable<any> {
    const params = new HttpParams().set('cpfCnpj', id);
    return this.http.get(`${this.url}/usuarios`, { params: params }).map(data => {
      return data;
    });
  }

  obterUsuario(codigoUsuario): Observable<any> {
    return this.http.get(`${this.url}/usuarios/${codigoUsuario}`).pipe(map(data => {
      const body = data['resultado'];
      return body;
    }));
  }

  consultarUsuariosAtivos(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/usuarios/ativos`, { params: params }).map(data => {
      return data;
    });
  }
  
  autenticarGovBR(usuarioGov: any): Observable<any> {
    const objuser = usuarioGov;
    return this.http
      .post(`${this.url}/autenticarGovBR`, objuser)
      .map(data => {
        const body = data;
        return body;
      });
  }

  buscarProcurador(cpfCnpj): Observable<any> {
    const params = new HttpParams().set('cpfCnpj', cpfCnpj);
    return this.http
      .get(`${this.url}/usuarios/procuradores/ativos`, { params: params })
      .map(data => {
        return data;
      });
  }

  getApiExterna(cpfCnpj): Observable<any> {
    return this.http.get(`${this.url}/contribuintes/autocadastro?cpfCnpj=${cpfCnpj}`).map(data => {
      const body = data;
      return body;
    });
  }

  getAutoCadastro(): Observable<any> {
    return this.http.get(`${this.url}/clientes/verificacaoIntegracaoAutocadastro`).map(data => {
      const body = data;
      return body;
    });
  }

  getTermoAdesao(codigoUsuario, cliente): Observable<any> {
    return this.http.get(`${this.url}/obterTermoAdesao?codigoCliente=${cliente}&codigoUsuario=${codigoUsuario}`).map(data => {
      const body = data;
      return body;
    });
  }

  gerarDocumento(data): Observable<any> {
    const fd = new FormData();
    fd.append('certificado', data.certificado);
    fd.append('codigoCliente', data.codigoCliente);
    fd.append('codigoUsuario',data.codigoUsuario);
    return this.http
      .post(`${this.url}/gerarDocumentoCiencia`, fd, {
        headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
      })
      .map(data => {
        console.log('data ', data);
        return data;
      });
  }

  darCiencia(codigoCliente, codigoUsuario, documento): Observable<any> {
    console.log('documento:::::::', documento)
    return this.http
      .post(`${this.url}/darCiencia?codigoCliente=${codigoCliente}&codigoUsuario=${codigoUsuario}`, 
        documento
      )
      .map(data => {
        return data;
      });
  }
  getPersonalidadesJuridicasAtivos(): Observable<any> {
    return this.http.get(`${this.url}/personalidadesJuridicas/ativos`).map(data => {
      const body = data;
      return body;
    });
  }
  
  getSetoresAtuacaoAtivos(): Observable<any> {
    return this.http.get(`${this.url}/setoresAtuacao/ativos`).map(data => {
      const body = data;
      return body;
    });
  }

  validarAdesaoAtiva(codUsuario, codTermoAdesao) {
    const params = new HttpParams({
      fromObject: {
        codUsuario: codUsuario,
        codTermoAdesao: codTermoAdesao
      }
    });
    return this.http.get(`${this.url}/adesao/validaAdesao`, { params: params }).map(data => { return data })
  }

}
