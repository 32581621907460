import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class IntegracaoService {

  private url = environment.BASE_URL;
  private dadosIntegracaoSubject = new Subject<any>();
  dadosIntegracao$ = this.dadosIntegracaoSubject.asObservable();

  constructor(private http: HttpClient) { }

  integracaoGovBr(code: any, state: any): Observable<any> {
    return this.http.get(`${this.url}/validarIntegracao/?code=${code}&state=${state}`);
  }

  sendDadosIntegracao() {
    this.dadosIntegracaoSubject.next();
  }
}
