import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { IndexRoutingModule } from './index.routing.module';
import { IndexComponent } from './paginas/index.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const routes: Routes = [{
  path: 'home',
  component: IndexComponent,
}]

@NgModule({
  
  imports: [ 
    IndexRoutingModule,
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    RouterModule.forChild(routes,)
  ],
  exports: [
    RouterModule
  ],
  declarations: [ 
    IndexComponent
  ],
  providers: [],
})
export class IndexModule { }
